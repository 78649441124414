import { createSlice } from "@reduxjs/toolkit";

interface Setting {
  loading: boolean;
}

const initialState: Setting = {
  loading: false,
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    AToggleLoding: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { AToggleLoding } = settingSlice.actions;
export default settingSlice.reducer;
