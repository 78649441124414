import React, {
  useState,
  ChangeEvent,
  useEffect,
  KeyboardEvent,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { useFetch } from "../../hooks/useFetch";
import { toast } from "react-toastify";
import DynamicButton from "../../component/button";

const OTPInput: React.FC = () => {
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const [disable, setDisable] = useState<boolean>(false);
  const refs = useRef<any[]>([]);
  const [seconds, setSeconds] = useState<number>(30);
  const [isTimerActive, setIsTimerActive] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const router = useNavigate();
  const state = useSelector((state: RootState) => state);
  const handleChange = (
    element: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = element.target.value;
    // Allow clearing the input (handle backspace or delete)
    setOtp([...otp.map((d, idx) => (idx === index ? value : d))]);

    if (value !== "" && !isNaN(Number(value))) {
      // Move to the next input if value is a valid number
      if (
        refs.current[index].nextSibling &&
        (refs.current[index].nextSibling as HTMLInputElement).focus
      ) {
        (refs.current[index].nextSibling as HTMLInputElement).focus();
      }
    }
  };

  const onKeyDown = (
    element: KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (element.code === "Backspace" || element?.keyCode === 8) {
      // Clear the current input and focus on the previous one
      setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);

      if (refs.current[index].previousSibling) {
        (refs.current[index].previousSibling as HTMLInputElement).focus();
      }
      return;
    }
  };

  const next = () => {
    if (state.workDetail) {
      const payload = {
        otp: otp?.join(""),
        ...state.workDetail,
        familyInvitationId: state.invitation.invitation?.id,
      };
      setLoader(true);
      return useFetch
        .post("family/verify-otp-family", payload)
        .then(() => {
          setLoader(false);
          return router("/accept");
        })
        .catch((err) => {
          setLoader(false);
          setOtp(new Array(6).fill(""));
          return toast.error(err?.response?.data?.message);
        });
    }
  };
  const resendOtp = () => {
    if (!isTimerActive || seconds <= 0) {
      setDisable(true);
      return useFetch
        .get(
          `family/send-otp-family?familyInvitationId=${state?.invitation.invitation?.id}`
        )
        .then(() => {
          setSeconds(30);
          toast.success("OTP is resend succesfully!");
          setDisable(false);
          return setIsTimerActive(true);
        });
    }
  };
  // Effects
  useEffect(() => {
    if (!state.invitation.invitation) router("/invalid");
  }, [state.invitation.invitation]);
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isTimerActive && seconds > 0) {
      timer = setTimeout(() => setSeconds(seconds - 1), 1000);
    } else if (seconds === 0) {
      setIsTimerActive(false);
    }

    return () => clearTimeout(timer);
  }, [isTimerActive, seconds]);
  return (
    <div className="flex flex-col items-center justify-between min-h-screen px-6 text-white py-14">
      <div className="flex flex-col items-center justify-center mt-32">
        <h1 className="text-2xl font-semibold mb-4">OTP darj karen</h1>
        <p className="text-center font-medium mb-6 px-4">
          Verification ke liye aap k number pe OTP code bheja gaya hai
        </p>
        <div className="flex space-x-2 mb-6 mt-4">
          {otp.map((data, index) => {
            return (
              <input
                className="w-12 h-12 text-center text-lg rounded bg-[#172A46] focus:outline-none focus:ring-2 focus:ring-primary-500"
                type="number"
                name="otp"
                maxLength={1}
                key={index}
                value={data}
                ref={(el) => (refs.current[index] = el)}
                onChange={(e) => handleChange(e, index)}
                onFocus={(e) => e.target.select()}
                onKeyDown={(e) => onKeyDown(e, index)}
              />
            );
          })}
        </div>
        <div className="mb-6 select-none">
          <p className="text-center text-sm">
            Didn't receive the code?{" "}
            <span
              onClick={resendOtp}
              className={`${
                isTimerActive || disable ? "text-gray-400" : "text-secondary"
              } cursor-pointer font-medium mx-2`}
            >
              Resend code
            </span>
            {isTimerActive && (
              <span className="text-secondary font-medium">({seconds})</span>
            )}
          </p>
        </div>
      </div>
      <DynamicButton
        onClick={next}
        isDisabled={otp?.join("")?.length < 6}
        isLoading={loader}
      >
        CONFIRM
      </DynamicButton>
    </div>
  );
};

export default OTPInput;
