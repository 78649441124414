import { combineReducers } from "@reduxjs/toolkit";
import invitationReducer from "./invitation";
import settings from "./settings";
import workDetail from "./work-detail";

const rootReducer = combineReducers({
  invitation: invitationReducer,
  setting: settings,
  workDetail,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
