import React, { useCallback, useEffect, useState } from "react";
import DynamicButton from "../../component/button";
import _, { method } from "lodash";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cnicFormatter, handleMaskingCNIC } from "../../helper/utils";
import { useFetch } from "../../hooks/useFetch";
import { RootState } from "../../store";
import { AUpdateData } from "../../store/reducer/work-detail";
import dayjs from "dayjs";

const BottomSheet: React.FC<{
  isOpen: boolean;
  data?: any;
  toggle?: any;
}> = (props: { isOpen: boolean; data?: any; toggle?: any }) => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const state = useSelector((state: RootState) => state.invitation.invitation);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
  } = useForm({
    reValidateMode: "onChange",
  });
  // Constant
  const cnic = watch("cnic");
  // Functions
  const debouncedSearch = useCallback(
    _.debounce((query) => {
      return checkUserByCnic(query);
    }, 100),
    []
  );

  const sendOTP = () => {
    return useFetch.get(
      `family/send-otp-family?familyInvitationId=${state?.id}`
    );
  };
  const next = async (data: any) => {
    let { cnic, ...rest } = data;
    console.log("-___data", data);

    cnic = cnicFormatter(cnic);
    const payload = {
      cnic,
      ...rest,
      officialDetails: { ...data, cnic },
      dob: rest?.issueDate,
    };
    setLoader(true);
    return checkUserByCnic(cnic).then((res) => {
      if (res) setLoader(true);
      console.log("res", res);
      return sendOTP()
        .then(() => {
          setLoader(false);
          dispatch(AUpdateData(payload));
          return router("/otp");
        })
        .catch(() => {
          return setLoader(false);
        });
    });
  };
  const checkUserByCnic = (cnic: string) => {
    setLoader(true);
    return useFetch
      .get(`auth/check-user-by-cnic?cnic=${cnic}`)
      .then((res) => {
        setLoader(false);
        if (res?.data?.data?.isUser === false) return clearErrors("cnic");
        setError("cnic", {
          message: "CNIC already registered in our system.",
        });
        return false;
      })
      .catch((err) => {
        setLoader(false);
        if (!err?.response)
          return setError("cnic", {
            message: "Something went wrong. Please try again",
          });
        if (err?.response?.data?.data) {
          if (
            err?.response?.data?.code === 400 &&
            err?.response?.data?.message?.includes("disabled")
          )
            return setError("cnic", {
              message: "User already registered",
            });
          clearErrors("cnic");
          return true;
        }
        if (
          err?.response?.data?.code !== 404 ||
          err?.response?.data?.code !== 400
        )
          return setError("cnic", {
            message: err?.response?.data?.message,
          });

        clearErrors("cnic");
        return true;
      });
  };

  // Effects
  useEffect(() => {
    if (cnic) {
      if (cnicFormatter(cnic)?.length === 13)
        debouncedSearch(cnicFormatter(cnic));
      setValue("cnic", handleMaskingCNIC(cnic));
    }
  }, [cnic, debouncedSearch]);
  useEffect(() => {
    if (props?.data) {
      Object.keys(props?.data).forEach((val: string) => {
        console.log("___val", val, props.data);

        if (val?.includes("Date")) {
          if (props?.data[val]) {
            const value = props?.data[val];
            const [day, month, year] = value.split("-");
            return setValue(val, `${year}-${month}-${day}`);
          }
        }
        return setValue(val, props?.data[val]);
      });
    }
  }, [props?.data]);
  console.log("__expiryDate", getValues("issueDate"));

  return props?.isOpen ? (
    <div
      id="app"
      className={`h-screen w-full text-center top-0 fixed z-50 bg-opacity-30 transition duration-300 ease-in-out ${
        props?.isOpen ? "bg-black" : ""
      }`}
    >
      <div
        id="sheet"
        className={`fixed absolute  z-100 w-full left-0 bottom-0 transform ${
          props?.isOpen ? "translate-y-0" : "translate-y-full"
        } transition duration-300 ease-in-out `}
      >
        <div className="p-8 bg-[#172A46] overflow-y-auto  rounded-t-3xl text-left flex flex-col md-shadow">
          <div className="w-full flex justify-end">
            <img
              src="./assets/close.svg"
              className="w-8 cursor-pointer h-8"
              onClick={props?.toggle}
              alt=""
            />
          </div>
          <h1 className="text-[#CCD7F7] text-lg font-medium">Verify Details</h1>
          <p className="text-[#8A98B8] font-medium text-sm mt-2 mb-4">
            Please verify your information
          </p>
          <div className="flex flex-col items-start justify-start">
            <form
              onSubmit={handleSubmit(next)}
              className="flex flex-col w-full flex flex-col "
            >
              <div className="flex flex-col py-6 space-y-8">
                <div className="flex flex-col">
                  <label
                    htmlFor="name"
                    className="text-white text-lg font-medium"
                  >
                    Name
                  </label>
                  <div className="flex flex-row w-full border-b-info border-b-2 border-opacity-25">
                    <input
                      type="text"
                      id="name"
                      className="bg-transparent text-info w-full pb-2 mt-3"
                      placeholder="Enter Name"
                      {...register("name", {
                        required: {
                          value: true,
                          message: "Please enter name",
                        },
                      })}
                    />
                    <img
                      src="./assets/edit.png"
                      className="w-8 cursor-pointer h-8"
                      alt=""
                    />
                  </div>
                  <p className="text-red-400 text-sm mt-2">
                    {errors["name"]?.message as any}
                  </p>
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="cnic"
                    className="text-white text-lg font-medium"
                  >
                    CNIC
                  </label>
                  <div className="flex flex-row w-full border-b-info border-b-2 border-opacity-25">
                    <input
                      type="text"
                      id="cnic"
                      className="bg-transparent text-info w-full pb-2 mt-3"
                      placeholder="xxxxx-xxxxxxx-x"
                      {...register("cnic", {
                        required: {
                          value: true,
                          message: "Please enter CNIC",
                        },
                        pattern: {
                          value: /^[0-9]{5} - [0-9]{7} - [0-9]$/,
                          message: "Please enter valid CNIC",
                        },
                      })}
                    />
                    <img
                      src="./assets/edit.png"
                      className="w-8 cursor-pointer h-8"
                      alt=""
                    />
                  </div>
                  <p className="text-red-400 text-sm mt-2">
                    {errors["cnic"]?.message as any}
                  </p>
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="issueDate"
                    className="text-white text-lg font-medium"
                  >
                    CNIC Issue Date
                  </label>
                  <input
                    type="date"
                    id="issueDate"
                    className="bg-transparent text-info border-b-info border-b-2 pb-2 mt-3 border-opacity-25"
                    {...register("issueDate", {
                      required: {
                        value: true,
                        message: "Please enter CNIC Issue Date",
                      },
                      validate: {
                        notInFuture: (value) => {
                          const today = new Date();
                          const selectedDate = new Date(value);
                          return (
                            selectedDate <= today ||
                            "CNIC Date cannot be in the future"
                          );
                        },
                      },
                    })}
                  />
                  <p className="text-red-400 text-sm mt-2">
                    {errors["issueDate"]?.message as any}
                  </p>
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="expiryDate"
                    className="text-white text-lg font-medium"
                  >
                    Expiry Date
                  </label>
                  <input
                    type="date"
                    id="expiryDate"
                    className="bg-transparent text-info border-b-info border-b-2 pb-2 mt-3 border-opacity-25"
                    placeholder="xxxxx-xxxx-xxx-x"
                    {...register("expiryDate", {
                      required: {
                        value: true,
                        message: "Please enter expiryDate",
                      },
                      validate: {
                        notInFuture: (value) => {
                          const today = new Date();
                          const selectedDate = new Date(value);
                          return (
                            selectedDate >= today ||
                            "Expiry cannot be in the past"
                          );
                        },
                      },
                    })}
                  />
                  <p className="text-red-400 text-sm mt-2">
                    {errors["expiryDate"]?.message as any}
                  </p>
                </div>
              </div>
              <div className="-mb-16 w-full flex items-center justify-center">
                <DynamicButton
                  isDisabled={Object.keys(errors)?.length > 0}
                  type="submit"
                  isLoading={loader}
                >
                  NEXT
                </DynamicButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default BottomSheet;
