import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer/root.reducer"; // Adjust the path based on your project structure

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
