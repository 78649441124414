import { createSlice } from "@reduxjs/toolkit";

interface WorkDetail {
  cnic?: string;
  dob?: string;
  issueDate?: string;
  officialDetails?: any;
}

const initialState: WorkDetail = {};

const workdDetailSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    AUpdateData: (state, action) => {
      state.cnic = action.payload.cnic;
      state.dob = action.payload.dob;
      state.issueDate = action.payload.issueDate;
      state.officialDetails = action.payload.officialDetails;
    },
  },
});

export const { AUpdateData } = workdDetailSlice.actions;
export default workdDetailSlice.reducer;
