import React, { memo, useCallback, useEffect, useState } from "react";
import { uploadFileToS3 } from "../../helper/utils";

import { useFetch } from "../../hooks/useFetch";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import _ from "lodash";
import DynamicButton from "../../component/button";
import BottomSheet from "./sheet";
import { useNavigate } from "react-router-dom";
function WorkDetails() {
  // Hooks
  const router = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<any>(null);
  const state = useSelector((state: RootState) => state.invitation.invitation);
  const [open, setOpen] = useState<boolean>(false);
  const [response, setResponse] = useState<any>(null);
  // Constant
  const uploadImage = async () => {
    setLoader(true);

    if (file) {
      const { type: fileType, name: fileName } = file;
      const type = fileName.split(".")[fileName.split(".").length - 1];
      const { data }: any = await useFetch.get("medias/family-media", {
        params: {
          requestType: "upload",
          scope: "any",
          fileName: `${new Date().getTime()}.${type}`,
          fileType,
          folderName: "family",
          key: state?.key,
        },
      });
      const url = data?.data?.signedUrl;
      await uploadFileToS3(url, file);
      const urlPath = data?.data?.urlPath;
      const value = await useFetch.post("family/fetch-cnic-details", {
        path: urlPath,
      });
      setLoader(false);
      setOpen(true);
      return setResponse(value?.data?.data);
    }
  };

  useEffect(() => {
    if (!state) router("/invalid");
  }, [state]);
  useEffect(() => {
    if (file) {
      const reader = new FileReader();

      // When file is loaded, update the image preview
      reader.onloadend = () => {
        setPreview(reader.result);
      };

      // Read the file as a data URL (base64 encoded)
      reader.readAsDataURL(file);
    }
  }, [file]);
  return (
    <>
      <BottomSheet
        toggle={() => setOpen(!open)}
        isOpen={open}
        data={response}
      />
      <div className="flex flex-col p-5 pt-10 items-start justify-between h-[100vh] w-full overflow-hidden">
        <div className="w-full bg-red-">
          <h1 className="text-white text-2xl border-b-2 border-info border-opacity-25 pb-2 pr-6 inline">
            Your Details
          </h1>
          <input
            type="file"
            className="hidden"
            id="file"
            disabled={loader}
            accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
            onChange={(e: any) => setFile(e?.target?.files[0])}
          />
          <p className="text-info mt-8">
            Apnay CNIC kay front ki picture upload karein
          </p>
          <label
            htmlFor="file"
            className="w-full group h-[220px] shadow-lg bg-[#172A46] relative rounded-xl mt-20 overflow-hidden flex items-center justify-center"
          >
            {preview && (
              <div className="w-full flex items-center h-full justify-center opacity-0 group-hover:bg-black/60 group-hover:opacity-100 cursor-pointer absolute transition-all">
                <div className="flex items-center flex-col">
                  <p className="text-white text-sm">
                    Click karke CNIC Change kraye.
                  </p>
                </div>
              </div>
            )}
            {preview ? (
              <img className="w-[65%] rounded-lg" src={preview} />
            ) : (
              <div className="flex items-center flex-col">
                <p className="w-10 rounded-full h-10 border-2 border-info mb-2 flex items-center justify-center text-info text-2xl">
                  +
                </p>
                <p className="text-info text-sm">
                  Click karke CNIC Front Side add karen
                </p>
              </div>
            )}
          </label>
        </div>
        <DynamicButton
          isDisabled={file === null}
          type="submit"
          isLoading={loader}
          onClick={uploadImage}
        >
          NEXT
        </DynamicButton>
      </div>
    </>
  );
}

export default memo(WorkDetails);
