import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { getOS } from "../../helper/utils";

const Accept: React.FC = () => {
  // Hooks
  const getDownloadLink = () => {
    const os = getOS();
    if (os === "iOS" || os === "macOS")
      return window.location.replace(
        "https://apps.apple.com/pk/app/abhi-your-salary-now/id1575706650"
      );
    return window.location.replace(
      "https://play.google.com/store/apps/details?id=com.abhifinance&hl=en"
    );
  };
  const state = useSelector((state: RootState) => state.invitation.invitation);
  return (
    <div className="flex flex-col items-center justify-between min-h-screen text-white py-20">
      <div className="flex flex-col items-center mt-32">
        <img
          src="./assets/salary.svg"
          alt="App Logo"
          className="mb-6 w-24 h-24"
        />
        <h1 className="text-sm mb-2">Aap ka balance hai</h1>
        <p className="text-4xl font-medium text-secondary mb-8">
          PKR {state?.limit?.toLocaleString()}
        </p>
        <p className="text-center text-sm px-20 font-medium">
          Apna balance access karnay k liye foran Abhi ki app download karein
          aur paise nikalain
        </p>
      </div>
      <button
        onClick={getDownloadLink}
        className="bg-secondary rounded-lg mt-16 text-[17px] border-none text-primary px-6 py-2.5 w-3/4 font-medium"
      >
        ACCEPT AND DOWNLOAD APP
      </button>
    </div>
  );
};

export default Accept;
