import axios from "axios";

const useFetch = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
useFetch.interceptors.request.use(
  (config) => {
    if (config.url) {
      config.url = config.baseURL + config.url;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export { useFetch };
