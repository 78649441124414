import React, { memo, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { useDispatch } from "react-redux";
import { AUpdateInvitation } from "../../store/reducer/invitation";
import { AToggleLoding } from "../../store/reducer/settings";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

function Welcome() {
  // Hooks
  const router = useNavigate();
  const [query] = useSearchParams();
  const [token, setToken] = useState<string>("");
  const [mounted, setMounted] = useState<boolean>(false);
  const state = useSelector(
    (state: RootState) => state?.invitation?.invitation
  );
  const name = useMemo(() => {
    return state?.invitedBy?.person?.firstName
      ? `${state?.invitedBy?.person?.firstName} ${state?.invitedBy?.person?.lastName}`
      : "";
  }, [state]);
  const dispatch = useDispatch();
  // Functions
  const next = () => {
    return router("/work-details");
  };
  // Effects
  useEffect(() => {
    if (!mounted) setMounted(true);
    return () => setMounted(false);
  }, []);
  useEffect(() => {
    if (mounted) {
      const token = query.get("token");
      if (token) setToken(token);
      else router("/invalid");
    }
  }, [mounted]);
  useEffect(() => {
    if (token) {
      dispatch(AToggleLoding());
      useFetch
        .post("family/validate-token", {
          token,
        })
        .then((res) => {
          const { data } = res;
          dispatch(AToggleLoding());
          return dispatch(AUpdateInvitation({ ...data?.data, key: token }));
        })
        .catch(() => {
          dispatch(AToggleLoding());
          return router("/invalid");
        });
    }
  }, [token]);
  return (
    <div className="flex flex-1 h-full flex items-center justify-center flex-col pb-20">
      <img src="/assets/main.svg" className="w-40" alt="" />
      <p className="text-white mt-4 text-2xl">You are invited to</p>
      <h1 className="text-white text-2xl font-bold mt-2">Abhi Family!</h1>
      <p className="text-sm mt-16 text-white text-center px-16 font-medium">
        {name} has invited you to become their family member.
      </p>
      <p className="text-sm mt-6 text-info text-center px-6 font-medium">
        {name} ne aap kay sath kuch amount share ki hai jo kay aap Abhi ki app
        download kar kay nikaal sakte hain.
      </p>
      <button
        onClick={next}
        className="bg-secondary rounded-lg mt-16 text-[17px] border-none text-primary px-6 py-2.5 w-2/3 font-medium"
      >
        CONTINUE
      </button>
    </div>
  );
}

export default memo(Welcome);
