import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Welcome from "./pages/welcome";
import "./index.css";
import WorkDetail from "./pages/work-detail";
import OTPInput from "./pages/otp";
import Accept from "./pages/accept";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import Invalid from "./pages/invalid";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Welcome />,
  },
  {
    path: "/work-details",
    element: <WorkDetail />,
  },
  {
    path: "/otp",
    element: <OTPInput />,
  },
  {
    path: "/accept",
    element: <Accept />,
  },
  {
    path: "/invalid",
    element: <Invalid />,
  },
]);

const Router = () => {
  // Hooks
  const loading = useSelector((state: RootState) => state.setting.loading);
  // Effects
  return (
    <>
      <ToastContainer />
      <div className="bg-primary">
        <div className="w-full h-[100vh] max-w-md bg-primary mx-auto">
          {loading && (
            <>
              <div className="w-full h-full bg-black bg-opacity-50 flex items-center justify-center absolute top-0 left-0">
                <img src="./assets/cashe.gif" alt="" className="w-48 h-48" />
              </div>
            </>
          )}
          <RouterProvider router={router} />
        </div>
      </div>
    </>
  );
};
export default Router;
