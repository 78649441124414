import React from "react";

const Invalid: React.FC = () => {
  // Hooks
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-white">
      <div className="flex flex-col items-center">
        <img
          src="./assets/caution.svg"
          alt="App Logo"
          className="mb-6 w-24 h-24"
        />
        <h1 className="text-sm mb-2">Link has been Expired!</h1>

        <p className="text-center text-sm px-20 font-medium">
          Apka link expired ho gya han. Dobara koshish kray!
        </p>
      </div>
    </div>
  );
};

export default Invalid;
