import { createSlice } from "@reduxjs/toolkit";
import { InvitationState } from "./type";

const initialState: InvitationState = {
  invitation: null,
};

const invitationSlice = createSlice({
  name: "invitation",
  initialState,
  reducers: {
    AUpdateInvitation: (state, action) => {
      state.invitation = action.payload;
    },
  },
});

export const { AUpdateInvitation } = invitationSlice.actions;
export default invitationSlice.reducer;
